import React, { useEffect, useState } from 'react'

import { colors } from '../utils'
import CoursVisio from '../svg/PROF_VOTREREMU_1.svg'
import CoursDomicile from '../svg/PROF_VOTREREMU_2.svg'
import Switch from "react-switch";

export default ({ dims }) => {

  const [ level1, setLevel1 ] = useState({});
  const [ level2, setLevel2 ] = useState({});
  const [ level3, setLevel3 ] = useState({});

  const fetchData = async () => {
    try {
      const levelsRes = await fetch('https://api.expliquemoipythagore.fr/v2/public/levels');
      let levels = await levelsRes.json();
      setLevel1(levels.find(({ name }) => name === 'CM2'))
      setLevel2(levels.find(({ name }) => name === 'Troisième'))
      setLevel3(levels.find(({ name }) => name === 'Terminale'))
    } catch (err) {
      console.error('Error fetching levels', err);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [ remote, setRemote ] = useState(false);

  return (
    <div style={{ width: '100%', maxWidth: 1200, position: 'relative', display: 'flex', flexDirection: 'column', paddingLeft: dims.x > 1200 ? 50 : 0, marginBottom: dims.x > 1200 ? 70 : 400 }}>
      <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 30, color: colors.dark_blue, width: dims.x > 1200 ? 450 : '100%', fontFamily: 'poppins', textTransform: 'uppercase', textAlign: dims.x > 1200 ? 'left' : 'center' }}>Votre rémunération</h2>
      {level1 && <div style={{ height: dims.x > 1200 ? 400 : 'auto', width: dims.x > 1200 ? 520 : '100%', position: 'relative', marginTop: dims.x > 1200 ? 0 : 150 }}>
        <div style={{ position: 'absolute', top: 0, left: dims.x > 1200 ? 0 : 30, backgroundColor: remote ? colors.blue : colors.skin, width: dims.x > 1200 ? 210 : 150, height: dims.x > 1200 ? 210 : 150, borderRadius: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30 }}>
          <h3 style={{ color: remote ? colors.dark_blue : colors.darkSkin, fontWeight: 'bold', fontSize: dims.x > 1200 ? 22 : 15, fontFamily: 'Poppins', marginBottom: 10 }}>PRIMAIRE</h3>
          <p style={{ color: colors.white, fontFamily: 'Poppins', marginBottom: 20, fontSize: 20}}>jusqu'à</p>
          <p style={{ color: colors.white, fontFamily: 'Poppins', margin: 0, fontWeight: 'bold', fontSize: dims.x > 1200 ? 70 : 40}}>{level1[remote ? 'remotePrice' : 'price']}€</p>
        </div>
        <div style={{ position: 'absolute', top: 0, right: dims.x > 1200 ? 0 : 30, backgroundColor: remote ? colors.blue : colors.skin, width: dims.x > 1200 ? 210 : 150, height: dims.x > 1200 ? 210 : 150, borderRadius: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30 }}>
          <h3 style={{ color: remote ? colors.dark_blue : colors.darkSkin, fontWeight: 'bold', fontSize: dims.x > 1200 ? 22 : 15, fontFamily: 'Poppins', marginBottom: 10 }}>Collège</h3>
          <p style={{ color: colors.white, fontFamily: 'Poppins', marginBottom: 20, fontSize: 20}}>jusqu'à</p>
          <p style={{ color: colors.white, fontFamily: 'Poppins', margin: 0, fontWeight: 'bold', fontSize: dims.x > 1200 ? 70 : 40}}>{level2[remote ? 'remotePrice' : 'price']}€</p>
        </div>
        <div style={{ position: 'absolute', bottom: 0, left: 155, backgroundColor: remote ? colors.blue : colors.skin, width: dims.x > 1200 ? 210 : 150, height: dims.x > 1200 ? 210 : 150, borderRadius: 110, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30 }}>
          <h3 style={{ color: remote ? colors.dark_blue : colors.darkSkin, fontWeight: 'bold', fontSize: dims.x > 1200 ? 22 : 15, fontFamily: 'Poppins', marginBottom: 10 }}>Lycée</h3>
          <p style={{ color: colors.white, fontFamily: 'Poppins', marginBottom: 20, fontSize: 20}}>jusqu'à</p>
          <p style={{ color: colors.white, fontFamily: 'Poppins', margin: 0, fontWeight: 'bold', fontSize: dims.x > 1200 ? 70 : 40}}>{level3[remote ? 'remotePrice' : 'price']}€</p>
        </div>
        <p style={{ fontSize: 14, color: colors.gray, fontWeight: 'bold', position: 'absolute', bottom: dims.x > 1200 ? -20 : 100, left: dims.x > 1200 ? 0 : 20, fontFamily: 'Poppins', opacity: 0.6 }}>Prix horaires nets</p>
      </div>}
      <div style={{ display: 'flex', flexDirection: dims.x > 1200 ? 'column' : 'column-reverse', alignItems: 'center', position: 'absolute', right: 0, top: dims.x > 1200 ? 150 : 350, zIndex: 1, width: dims.x > 1200 ? '50%' : '100%' }}>
        {remote ? <CoursVisio width={dims.x > 1200 ? 600 : '80%'}/> : <CoursDomicile width={dims.x > 1200 ? 600 : '80%'}/>}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
          <p style={{ color: colors.dark_blue, fontWeight: 'bold', fontFamily: 'Poppins', margin: 0, marginRight: 15 }}>à domicile</p>
          <Switch checkedIcon={null} uncheckedIcon={null} handleDiameter={20} activeBoxShadow={'0 0 0px'} height={5} width={50} offHandleColor={colors.skin} onHandleColor={colors.blue} offColor={'#dddddd'} onColor={'#dddddd'} checked={remote} onChange={(v) => {console.log('v', v); setRemote(v) }}/>
          <p style={{ color: colors.dark_blue, fontWeight: 'bold', fontFamily: 'Poppins', margin: 0 , marginLeft: 15 }}>à distance</p>
        </div>
      </div>
    </div>
  )
}
