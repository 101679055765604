import React from 'react'

import { colors } from '../utils'
import Fond05 from '../svg/fond_05.svg'
import Rejoindre1 from '../svg/rejoindre1.svg';
import Rejoindre2 from '../svg/rejoindre2.svg';
import Rejoindre3 from '../svg/rejoindre3.svg';
import Rejoindre4 from '../svg/rejoindre4.svg';

export default ({ dims }) => {
  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: dims.x > 1200 ? 50 : 0, marginBottom: dims.x > 1200 ? 100 : 0, backgroundColor: dims.x > 1200 ? 'transparent' : '#D9E9F4' }}>
      <div style={{ width: '100%', maxWidth: 1200, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: dims.x > 1200 ? 200 : 50 }}>
        <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 35, color: colors.dark_blue, fontFamily: 'poppins', textTransform: 'uppercase', textAlign: dims.x > 1200 ? 'left' : 'center' }}>Pourquoi nous rejoindre</h2>
        <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', justifyContent: 'space-between', alignItems: dims.x > 1200 ? 'flex-end' : 'center', marginTop: 50 }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: dims.x > 1200 ? '15%' : '80%', height: dims.x > 1200 ? 500 : 'auto' }}>
            <Rejoindre1 />
            <h3 style={{ textTransform: 'uppercase', fontFamily: 'Poppins', textAlign: dims.x > 1200 ? 'left' : 'center', color: colors.dark_blue, fontWeight: 'bold', marginTop: 20, fontSize: 24 }}>Rémunération attractive</h3>
            <p style={{ marginBottom: dims.x > 1200 ? 0 : 80, textAlign: dims.x > 1200 ? 'left' : 'center', fontFamily: 'Poppins', color: colors.gray, fontSize: 16, fontWeight: 'bold' }}>Nos professeurs sont mieux rémunérés de 40% par rapport à la concurrence</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: dims.x > 1200 ? '15%' : '80%', height: dims.x > 1200 ? 500 : 'auto' }}>
            <Rejoindre2 />
            <h3 style={{ textTransform: 'uppercase', fontFamily: 'Poppins', textAlign: dims.x > 1200 ? 'left' : 'center', color: colors.dark_blue, fontWeight: 'bold', marginTop: 20, fontSize: 24 }}>Sans contrainte horaire</h3>
            <p style={{ marginBottom: dims.x > 1200 ? 0 : 80, textAlign: dims.x > 1200 ? 'left' : 'center', fontFamily: 'Poppins', color: colors.gray, fontSize: 16, fontWeight: 'bold' }}>Les demandes de cours s'affichent sur l'application, libre à vous de les accepter ou non</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: dims.x > 1200 ? '15%' : '80%', height: dims.x > 1200 ? 500 : 'auto' }}>
            <Rejoindre3 />
            <h3 style={{ textTransform: 'uppercase', fontFamily: 'Poppins', textAlign: dims.x > 1200 ? 'left' : 'center', color: colors.dark_blue, fontWeight: 'bold', marginTop: 20, fontSize: 24 }}>Simple d'utilisation</h3>
            <p style={{ marginBottom: dims.x > 1200 ? 0 : 80, textAlign: dims.x > 1200 ? 'left' : 'center', fontFamily: 'Poppins', color: colors.gray, fontSize: 16, fontWeight: 'bold' }}>Depuis notre site internet ou notre application, un cours est accepté en 2 clics</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: dims.x > 1200 ? '15%' : '80%', height: dims.x > 1200 ? 500 : 'auto' }}>
            <Rejoindre4 />
            <h3 style={{ textTransform: 'uppercase', fontFamily: 'Poppins', textAlign: dims.x > 1200 ? 'left' : 'center', color: colors.dark_blue, fontWeight: 'bold', marginTop: 20, fontSize: 24 }}>Aucune commission</h3>
            <p style={{ marginBottom: dims.x > 1200 ? 0 : 80, textAlign: dims.x > 1200 ? 'left' : 'center', fontFamily: 'Poppins', color: colors.gray, fontSize: 16, fontWeight: 'bold' }}>Nous n'interférons pas dans le paiement, les familles vous règlent directement à la fin du cours</p>
          </div>
        </div>
      </div>
      {dims.x > 1200 && <div style={{ width: '100%', position: 'absolute', right: 0, top: 0, zIndex: -2 }}>
        <Fond05/>
      </div>}
    </div>
  )
}
