import React, { useState, useEffect } from "react"

import Header from "../components/header"
import SEO from "../components/seo"

import Prof from '../svg/prof_visio.svg'
import { colors } from '../utils'

import TutoTeacher from '../components/tutoTeacher'
import Remuneration from '../components/remuneration'
import Rejoindre from '../components/rejoindre'
import Stats from '../components/stats'
import Footer from '../components/footer'
import Background from '../svg/header.svg'
import { Link } from 'gatsby'
import Logo from '../svg/logo.svg'
import Contact from "../components/contact";

export default () => {

  const [ dims, setDims ] = useState({ x: 1200, y: 1200 });
  const [ yScroll, setYScroll ] = useState(0);
  const [ height, setHeight ] = useState();

  useEffect(() => {
    setDims({ x: window.innerWidth, y: window.innerHeight });
    setHeight(window.document.body.scrollHeight);
    window.addEventListener('resize', () => setDims({ x: window?.innerWidth || 0, y: window.innerHeight }));
    window.addEventListener('scroll', () => {
      setYScroll(window.pageYOffset);
      setDims({ x: window.innerWidth, y: window.innerHeight });
    })}, []);

  return (
    <>
      <Background width={'50%'} height={'100%'} style={{position: 'relative', zIndex: 501}}/>
      {dims.x > 800 &&
      <div style={{position: 'absolute', top: dims.x/50, left: dims.x/50, zIndex: 502}}>
        <Link to='/' aria-label='home'>
          <div style={dims.x > 1200 ? {transform: 'scale(1.2)'} : { transform: 'scale(0.7)' }}>
            <Logo />
          </div>
        </Link>
      </div>}
      <Header dims={dims} scroll={yScroll} height={height}/>
      <div style={styles.container}>
        <SEO />
        {dims.x > 1200 && <div style={{ position: 'absolute', top: -30, right: 0, zIndex: -1 }}>
          <Prof />
        </div>}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: dims.x > 1200 ? 'flex-start' : 'center', width: '100%', maxWidth: 1200, marginLeft: dims.x > 1200 ? 150 : 0, marginTop: dims.x > 1200 ? 100 : 0 }}>
          <h1 style={{ width: dims.x > 1200 ? 400 : '80%', color: colors.dark_blue, fontSize: 37, textAlign: dims.x > 1200 ? 'left' : 'center' }}>DEVENIR PROFESSEUR</h1>
          <ul style={{ width: 350, paddingLeft: 10, paddingRight: 10 }}>
            <li style={{ fontSize: 20, color: colors.blue, fontFamily: 'Poppins' }}>Vous êtes sérieux et pédagogue,</li>
            <li style={{ fontSize: 20, color: colors.blue, fontFamily: 'Poppins' }}>Vous avez le BAC et êtes diplômé du supérieur,</li>
            <li style={{ fontSize: 20, color: colors.blue, fontFamily: 'Poppins' }}>Et vous souhaitez avoir un revenu sans contraite horaire ?</li>
          </ul>
        </div>
        <TutoTeacher dims={dims} />
        <Remuneration dims={dims}/>
        <Rejoindre dims={dims}/>
        <a rel='noopener noreferrer' href='https://forms.gle/C67VWCN22G87y6j87' target='_blank' className='hover' style={{zIndex: 100, width: dims.x > 1200 ? 400 : '80%', height: dims.x > 1200 ? 60 : 60, backgroundColor: colors.blue, display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginTop: dims.x > 1200 ? 60 : 60 }}>
          <p style={{ margin: 0, color: colors.white, fontWeight: 450, fontFamily: 'poppins', fontSize: dims.x > 1200 ? 25 : 20, textAlign: 'center' }}>NOUS REJOINDRE</p>
        </a>
        {false && <Stats />}
        <Contact dims={dims} />
        <Footer dims={dims}/>
      </div>
    </>
  )
}

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  body: {
    width: 1200,
    marginTop: 100,
  },
}
