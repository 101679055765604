import React, { useState } from 'react'

import { colors } from '../utils'
import './hover.css';
import Fond03 from '../svg/fond_03.svg'
import RightArrow from '../svg/right-arrow.svg'
import Tuto1 from '../svg/PROF_COMMENTNOUSJOINDRE_1.svg'
import Tuto2 from '../svg/PROF_COMMENTNOUSJOINDRE_2.svg'
import Tuto3 from '../svg/PROF_COMMENTNOUSJOINDRE_3.svg'

const steps = [
  'J\'envoie mon CV et je renseigne mes informations en cliquant sur le bouton << Nous rejoindre >> ci-dessus',
  'Votre candidature est retenue par notre équipe de recrutement, on vous contacte pour planifier un entretien',
  'Votre candidature est validée, vous pouvez à présent recevoir toutes les demandes de cours sur notre application, et donner des cours.  Bienvenue dans l\'équipe !',
]

export default ({ dims }) => {

  const [ step, setStep ] = useState(0);

  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: dims.x > 1200 ? 150 : 50 }}>
      {dims.x > 1200 && <div style={{ position: 'absolute', right: 0, top: -30, width: '100%', zIndex: -2 }}>
        <Fond03 width="100%" />
      </div>}
      <a rel='noopener noreferrer' href='https://forms.gle/C67VWCN22G87y6j87' target='_blank' className='hover' style={{ width: dims.x > 1200 ? 400 : '80%', height: dims.x > 1200 ? 60 : 60, backgroundColor: colors.blue, display: 'flex', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', marginTop: dims.x > 1200 ? 60 : 60 }}>
        <p style={{ margin: 0, color: colors.white, fontWeight: 450, fontFamily: 'poppins', fontSize: dims.x > 1200 ? 25 : 20, textAlign: 'center' }}>NOUS REJOINDRE</p>
      </a>
      <div style={{ width: dims.x > 1200 ? 1200 : '100%', display: 'flex', flexDirection: 'row', justifyContent: dims.x > 1200 ? 'space-between' : 'space-between', alignItems: 'center', marginTop: dims.x > 1200 ? 180 : 50, backgroundColor: dims.x > 1200 ? 'transparent' : colors.blue, paddingTop: dims.x > 1200 ? 0 : 50, paddingBottom: dims.x > 1200 ? 0 : 50 }}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: dims.x > 1200 ? '10%' : '10%', cursor: 'pointer', transform: 'rotate(180deg)', marginLeft: dims.x > 1200 ? 45 : 10, outline: 'none' }}
          onClick={() => setStep(step > 0 ? (step - 1)  : 0)} onKeyPress={() => setStep(step > 0 ? (step - 1)  : 0)} role='button' tabIndex='-3'
        >
          <RightArrow />
        </div>
        {dims.x > 1200 && <div style={{ position: 'absolute', top: 50, left: -350, zIndex: -2 }}>
          {step === 0 ?
            <Tuto1 height="950"/>
            : step === 1 ?
            <Tuto2 height="950"/>
            :
            <Tuto3 height="950"/>
          }
        </div>}
        {dims.x > 1200 && <div style={{ width: '35%' }}/>}
        <div style={{ marginLeft: dims.x > 1200 ? 35 : 0, width: dims.x > 1200 ? 500 : '75%' }}>
          <h2 style={{ color: colors.dark_blue, fontSize: dims.x > 1200 ? 49 : 30, width: dims.x > 1200 ? 400 : 'auto', textTransform: 'uppercase', fontFamily: 'Poppins', textAlign: dims.x > 1200 ? 'left' : 'center' }}>Comment ça marche ?</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 167, marginTop: dims.x > 1200 ? 50 : 50 }}>
            {dims.x > 1200 && <p style={{ textAlign: 'center', margin: 0, width: dims.x > 1200 ? 130 : '10%', fontSize: 180, color: 'white', fontFamily: 'poppins' }}>{step + 1}</p>}
            {dims.x > 1200 && <div style={{ backgroundColor: 'white', height: '100%', width: 2, marginRight: 35, marginLeft: 20 }}/>}
            <p style={{ color: 'white', fontFamily: 'poppins', fontSize: dims.x > 1200 ? 20 : 14, maxWidth: dims.x > 1200 ? 450 : '70%', margin: 0, textAlign: dims.x > 1200 ? 'left' : 'center' }}>{steps[step]}</p>
          </div>
          <div style={{ marginTop: 105, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 16, height: 16, borderRadius: 8, border: '2px solid white', marginRight: 20, backgroundColor: step === 0 ? 'white' : 'transparent' }}/>
            <div style={{ width: 16, height: 16, borderRadius: 8, border: '2px solid white', marginRight: 20, backgroundColor: step === 1 ? 'white' : 'transparent' }}/>
            <div style={{ width: 16, height: 16, borderRadius: 8, border: '2px solid white', backgroundColor: step === 2 ? 'white' : 'transparent' }}/>
          </div>
        </div>
        <div style={{ marginRight: dims.x > 1200 ? 45 : 10, cursor: 'pointer', outline: 'none' }} onClick={() => setStep(step < 2 ? (step + 1) : 2)} onKeyPress={() => setStep(step < 2 ? (step + 1) : 2)} role='button' tabIndex='-4'>
          <RightArrow />
        </div>
      </div>
    </div>
  )
}
